<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2">
              {{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}
            </span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span> {{ item }} </span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <div class="btn-group ml-5">
          <vs-button
            size="small"
            color="success"
            type="border"
            @click="handleExport('excel')"
            >Excel</vs-button
          >
        </div>
      </template>
      <template slot="thead">
        <vs-th sort-key="sales_office">Sales Office</vs-th>
        <vs-th sort-key="sales_office_desc">Sales Office Desc</vs-th>
        <vs-th sort-key="order_type">Order Type</vs-th>
        <vs-th sort-key="po_number">PO Number</vs-th>
        <vs-th sort-key="po_date">PO Date</vs-th>
        <vs-th sort-key="so_number">SO Number</vs-th>
        <vs-th sort-key="so_date">SO Date</vs-th>
        <vs-th sort-key="so_creation_date">SO Creation Date</vs-th>
        <vs-th sort-key="do_number">DO Number</vs-th>
        <vs-th sort-key="do_date">DO Date</vs-th>
        <vs-th sort-key="gi_number">GI Number</vs-th>
        <vs-th sort-key="gi_date">GI Date</vs-th>
        <vs-th sort-key="gi_dpp_amt">GI DPP Amt</vs-th>
        <vs-th sort-key="gi_ficodoc_ref_id">GI ficodoc Ref ID</vs-th>
        <vs-th sort-key="gi_posting_journal_status">
          GI Posting Journal Status
        </vs-th>
        <vs-th sort-key="gi_remarks">GI Remarks</vs-th>
        <vs-th sort-key="fg_gi_posted">FG_GI Posted</vs-th>
        <vs-th sort-key="bill_number">Bill Number</vs-th>
        <vs-th sort-key="bill_ficodoc_ref_id">Bill ficodoc Ref ID</vs-th>
        <vs-th sort-key="bill_posting_journal_status">
          Bill Posting Journal Status
        </vs-th>
        <vs-th sort-key="bill_remarks">Bill Remarks</vs-th>
        <vs-th sort-key="arposted">ARPosted</vs-th>
        <vs-th sort-key="updated_by">Updated By</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].sales_office">{{
            data[indextr].sales_office
          }}</vs-td
          ><vs-td :data="data[indextr].sales_office_desc">{{
            data[indextr].sales_office_desc
          }}</vs-td
          ><vs-td :data="data[indextr].order_type">{{
            data[indextr].order_type
          }}</vs-td
          ><vs-td :data="data[indextr].po_number">{{
            data[indextr].po_number
          }}</vs-td
          ><vs-td :data="data[indextr].po_date">{{
            data[indextr].po_date
          }}</vs-td
          ><vs-td :data="data[indextr].so_number">{{
            data[indextr].so_number
          }}</vs-td
          ><vs-td :data="data[indextr].so_date">{{
            data[indextr].so_date
          }}</vs-td
          ><vs-td :data="data[indextr].so_creation_date">{{
            data[indextr].so_creation_date
          }}</vs-td
          ><vs-td :data="data[indextr].do_number">{{
            data[indextr].do_number
          }}</vs-td
          ><vs-td :data="data[indextr].do_date">{{
            data[indextr].do_date
          }}</vs-td
          ><vs-td :data="data[indextr].gi_number">{{
            data[indextr].gi_number
          }}</vs-td
          ><vs-td :data="data[indextr].gi_date">{{
            data[indextr].gi_date
          }}</vs-td
          ><vs-td :data="data[indextr].gi_dpp_amt">{{
            data[indextr].gi_dpp_amt
          }}</vs-td
          ><vs-td :data="data[indextr].gi_ficodoc_ref_id">{{
            data[indextr].gi_ficodoc_ref_id
          }}</vs-td
          ><vs-td :data="data[indextr].gi_posting_journal_status">{{
            data[indextr].gi_posting_journal_status
          }}</vs-td
          ><vs-td :data="data[indextr].gi_remarks">{{
            data[indextr].gi_remarks
          }}</vs-td
          ><vs-td :data="data[indextr].fg_gi_posted">{{
            data[indextr].fg_gi_posted
          }}</vs-td
          ><vs-td :data="data[indextr].bill_number">{{
            data[indextr].bill_number
          }}</vs-td
          ><vs-td :data="data[indextr].bill_ficodoc_ref_id">{{
            data[indextr].bill_ficodoc_ref_id
          }}</vs-td
          ><vs-td :data="data[indextr].bill_posting_journal_status">{{
            data[indextr].bill_posting_journal_status
          }}</vs-td
          ><vs-td :data="data[indextr].bill_remarks">{{
            data[indextr].bill_remarks
          }}</vs-td
          ><vs-td :data="data[indextr].arposted">{{
            data[indextr].arposted
          }}</vs-td
          ><vs-td :data="data[indextr].updated_by">{{
            data[indextr].updated_by
          }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';

export default {
  components: {},
  props: {
    baseUrl: {
      type: String,
    },
    draw: {
      type: Number,
    },
  },
  computed: {
    ...mapState({
      tableData: (state) => state.reportO2CFlow.tableData,
      total: (state) => state.reportO2CFlow.total,
      totalPerPage: (state) => state.reportO2CFlow.totalPerPage,
      totalPage: (state) => state.reportO2CFlow.totalPage,
      totalSearch: (state) => state.reportO2CFlow.totalSearch,
    }),
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
    };
  },
  methods: {
    ...mapActions({
      getTableData: 'reportO2CFlow/getO2CFlowReport',
    }),
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: '',
        order: 'sales_office',
        sort: 'desc',
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, 'All'],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == 'All' ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getData() {
      this.$vs.loading();
      this.getTableData({
        length: this.table.length,
        page: this.table.page,
        search: this.table.search,
        order: this.table.order,
        sort: this.table.sort,
      }).then((resp) => {
        this.$vs.loading.close();
        this.table.data = resp.records;
      });
    },
    setStartEnd() {
      let valStart =
        this.tableData.length * this.table.page - this.tableData.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.tableData.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (this.totalSearch < this.table.total && this.table.search != '') {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    dateFormat(val) {
      return moment(val).format('DD MMM YYYY');
    },
    handleExport(file) {
      this.$vs.loading();
      var fileTitle =
        'ARTRANS_REPORT_' +
        (this.territoryNames.length > 0
          ? this.territoryNames.join('-')
          : 'all') +
        '_';

      if (this.startDocDate || this.endDocDate) {
        fileTitle +=
          'DOC_DATE_' +
          moment(this.startDocDate).format('YYYY-MM-DD') +
          '_-_' +
          moment(this.endDocDate).format('YYYY-MM-DD') +
          '_';
      }

      if (this.startPostingDate || this.endPostingDate) {
        'POSTING_DATE_' +
          moment(this.startPostingDate).format('YYYY-MM-DD') +
          '_-_' +
          moment(this.endPostingDate).format('YYYY-MM-DD') +
          '_';
      }

      if (this.startDueDate || this.endDueDate) {
        'DUE_DATE_' +
          moment(this.startDueDate).format('YYYY-MM-DD') +
          '_-_' +
          moment(this.endDueDate).format('YYYY-MM-DD') +
          '_';
      }

      fileTitle +=
        'OPEN_KEY_DATE_' + moment(this.openKeyDate).format('YYYY-MM-DD');

      this.$http
        .get(this.baseUrl + '/export', {
          params: {
            territory_ids: this.territoryIDs,
            start_posting_date: this.startPostingDate
              ? moment(this.startPostingDate).format('YYYY-MM-DD')
              : null,
            end_posting_date: this.endPostingDate
              ? moment(this.endPostingDate).format('YYYY-MM-DD')
              : null,
            start_doc_date: this.startDocDate
              ? moment(this.startDocDate).format('YYYY-MM-DD')
              : null,
            end_doc_date: this.endDocDate
              ? moment(this.endDocDate).format('YYYY-MM-DD')
              : null,
            start_due_date: this.startDueDate
              ? moment(this.startDueDate).format('YYYY-MM-DD')
              : null,
            end_due_date: this.endDueDate
              ? moment(this.endDueDate).format('YYYY-MM-DD')
              : null,
            open_key_date: this.openKeyDate
              ? moment(this.openKeyDate).format('YYYY-MM-DD')
              : null,
            file: file,
            title: fileTitle,
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.status == 'error') {
            this.$vs.notify({
              color: 'danger',
              title: 'Error',
              text: resp.message,
              position: 'top-right',
              iconPack: 'feather',
              icon: 'icon-x-circle',
            });
          } else {
            this.$vs.notify({
              color: 'success',
              title: 'Processing',
              text: resp.message,
              position: 'top-right',
              iconPack: 'feather',
              icon: 'icon-x-circle',
            });
          }
        });
    },
    formatPrice(val) {
      if (isNaN(val)) {
        val = 0;
      }
      val = (val / 1).toFixed(2).replace(',', '.');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    format(head, value) {
      if (typeof head['format'] === 'function') {
        var f = head['format'];
        return f(value);
      }
      return value;
    },
  },
  watch: {
    // detail() {
    //   this.getData();
    // },
    // startPostingDate() {
    //   this.getData();
    // },
    // endPostingDate() {
    //   this.getData();
    // },
    // startDocDate() {
    //   this.getData();
    // },
    // endDocDate() {
    //   this.getData();
    // },
    // startDueDate() {
    //   this.getData();
    // },
    // endDueDate() {
    //   this.getData();
    // },
    // openKeyDate() {
    //   this.getData();
    // },
    // territoryID() {
    //   this.getData();
    // },
    draw() {
      this.getData();
    },
    tableData(val) {
      this.table.data = val;
    },
    total(val) {
      this.table.total = val;
    },
    totalPerPage(val) {
      this.table.totalPerPage = val;
    },
    totalPage(val) {
      this.table.totalPage = val;
    },
    totalSearch(val) {
      this.table.totalSearch = val;
    },
    'table.data'() {
      this.setStartEnd();
    },
  },
  // mounted() {
  //   this.getData();
  // },
};
</script>
